body {
  margin: 0;
  overflow: hidden;
}

html {
  font-size: 20vw;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', 'sans-serif';
  h1,
  h2,
  h3,
  p,
  ul,
  li,
  figure,
  figcaption {
    margin: 0;
    padding: 0;
  }
  ul {
    list-style: none;
  }
  ul li {
    list-style-type: none;
  }
  img {
    border: none;
    vertical-align: middle;
  }

  // PC端 (屏幕宽度 > 768px)
  @media (min-width: 768px) {
    // font-size: 100px;
    font-size: 20vw;
  }
}

// PC端样式
.pc_page {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
}

// 移动端样式
.m_page {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  overflow: hidden;
}
