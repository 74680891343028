@import '../../index.scss';
$TopNavHeight: 0.63rem;

.bg {
  background-color: #f5f5f5;
  overflow: hidden;
  .top_title_block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: $TopNavHeight;
    background: linear-gradient(-27deg, rgba(254, 211, 47, 1), rgba(255, 177, 13, 1));
    .title {
      width: 1.62rem;
      height: 0.22rem;
    }
  }
  .main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0.14rem 0 0.29rem;
    width: 100%;
    height: calc(100vh - #{$TopNavHeight});
    overflow: auto;
    box-sizing: border-box;
    .item_block {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.21rem;
      width: 3.94rem;
      .item_title_block {
        margin-bottom: 0.1rem;
        font-size: 0.14rem;
        .star {
          color: #ff0101;
        }
        .item_title {
          color: #212121;
        }
      }
      .input {
        padding: 0.17rem 0.14rem 0.15rem;
        height: 0.56rem;
        font-size: 0.14rem;
        border-color: #e4e4e4;
        border-radius: 0.05rem;
        border-width: 1px;
        border-style: solid;
        box-sizing: border-box;
        -webkit-appearance: none;
        outline: none;
        &:focus {
          box-shadow: 0 0 0 1px #0076ff;
        }
      }
      .select {
        $SelectHeight: 0.56rem;
        position: relative;
        display: flex;
        height: $SelectHeight;
        border-color: #e4e4e4;
        border-radius: 0.05rem;
        border-width: 1px;
        border-style: solid;
        select {
          padding: 0.02rem 0.14rem 0;
          width: 100%;
          height: $SelectHeight;
          line-height: $SelectHeight;
          font-size: 0.14rem;
          border: none;
          border-radius: 0.05rem;
          background-color: #fff;
          //隐藏select的下拉图标
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          outline: none;
          &:focus {
            box-shadow: 0 0 0 1px #0076ff;
          }
        }
        &::after {
          content: '';
          position: absolute;
          right: 0.09rem;
          top: 0.25rem;
          width: 0px;
          height: 0px;
          border-top: 0.11rem solid #4b4b4b;
          border-left: 0.11rem solid transparent;
          border-right: 0.11rem solid transparent;
          //给自定义的图标实现点击下来功能
          pointer-events: none;
        }
      }
    }
    .submit_btn {
      $SubmitBtnHeight: 0.42rem;
      padding: 0 0.29rem;
      width: fit-content;
      height: $SubmitBtnHeight;
      line-height: $SubmitBtnHeight;
      font-size: 0.2rem;
      color: #313131;
      background: linear-gradient(142deg, rgba(254, 211, 47, 1), rgba(255, 177, 13, 1));
      border-radius: $SubmitBtnHeight / 2;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
}

.keep_record {
  width: 100%;
  font-size: 0.14rem;
  text-align: center;
  margin-top: 0.2rem;

  & > span {
    margin: 0 0.06rem;
  }
}
