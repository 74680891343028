@import '../../../../index.scss';

.bg {
  position: relative;
  display: flex;
  align-items: center;
  background-image: url('../../assets/about_us_bg_left.png');
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  .bg_right {
    $ImgHeight: 2.63rem;
    position: absolute;
    bottom: 0.15rem;
    right: 0;
    height: $ImgHeight;
  }
  .main {
    margin-left: 1.6rem;
    height: 100%;
    z-index: 99;
    .content {
      .title {
        margin-top: 0.55rem;
        &:nth-child(1) {
          margin-top: 1rem;
        }
        width: fit-content;
        font-size: 0.2rem;
        font-weight: 600;
        background: linear-gradient(to right, #f26486, #ff6e3d);
        -webkit-background-clip: text;
        color: transparent;
      }
      .text {
        margin-top: 0.05rem;
        li {
          font-size: 0.15rem;
          line-height: 0.25rem;
          color: #4b4b4b;
        }
      }
    }
    .qr_codes {
      display: flex;
      align-items: center;
      margin-top: 0.7rem;
      figure {
        display: flex;
        flex-direction: column;
        align-items: center;
        &:not(:nth-child(1)) {
          margin-left: 0.23rem;
        }
      }
      .img {
        $QR_CODE_SIZE: 0.9rem;
        width: $QR_CODE_SIZE;
        height: $QR_CODE_SIZE;
      }
      .title {
        margin-top: 0.04rem;
        font-size: 0.12rem;
        color: #6b6b6b;
      }
    }
    .contact {
      display: flex;
      flex-direction: column;
      margin-top: 0.35rem;
      .group {
        display: flex;
        flex-direction: column;
        .title,
        .content {
          font-size: 0.16rem;
          color: #343434;
        }
      }
    }
  }
}
